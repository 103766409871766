import Papa from 'papaparse'

export const parse = (contents) => {
  const list = []
  const results = Papa.parse(contents, { header: true })
  results.data.forEach((result) => {
    // console.log(results)
    const party = result['Names Together']
    list.push({
      'first name': result['First Name'],
      'last name': result['Last Name'] || '',
      email: result.Email,
      party,
    })

    // Partner
    if (result['Partner First Name']) {
      list.push({
        'first name': result['Partner First Name'],
        'last name': result['Partner Last Name'] || '',
        party,
      })
    }

    // Children
    for (let i = 1; i < 4; i++) {
      const childKey = 'Child ' + i
      if (result[childKey + ' Firstname']) {
        list.push({
          'first name': result[childKey + ' Firstname'],
          'last name': result['Last Name'],
          party,
        })
      }
    }
  })

  // let final = 'first name,last name,email,party\n'
  // list.forEach((guest) => {
  //   final +=
  //     guest['first name'] +
  //     ',' +
  //     guest['last name'] +
  //     ',' +
  //     (guest.email || '') +
  //     ',' +
  //     (guest.party || '') +
  //     '\n'
  // })

  return Papa.unparse(list)
}
