import { Link } from 'react-router-dom'

import logo from '../../logo.png'

import './style.css'

const Navbar = () => {
  return (
    <div className='container-fluid '>
      <div className='nav'>
        <Link className='logo' to='/'>
          <img src={logo} />
          Wedding Tools
        </Link>
      </div>
    </div>
  )
}

export default Navbar
