import { useRef } from 'react'

import { parse } from '../../services/postable'

import FromTo from '../../components/FromTo'

import './style.css'

function download(filename, text) {
  const element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  )
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

const Postable = () => {
  const ref = useRef()

  return (
    <div className='postable'>
      <div className='convert-content'>
        <div className='page-header'>
          <h1>Postable to WithJoy Guest List CSV Converter</h1>
          <FromTo />
        </div>
        <label class='custom-file-upload btn'>
          <input
            ref={ref}
            type='file'
            onChange={async (e) => {
              const file = e.target.files.item(0)
              const text = await file.text()
              download('guests.csv', parse(text))
              ref.current.value = ''
            }}
          />
          Choose CSV file
        </label>
      </div>
      <div className='container-fluid '>
        <h2>How to Convert your Postable Guest List to WithJoy Free</h2>
        <div className='row instructions'>
          <div className='col-xs-12 col-md-4'>
            1.) Export your gust list from Postable as a CSV using Excel or
            Google Docs.
          </div>
          <div className='col-xs-12 col-md-4'>
            2.) Upload your CSV file above.
          </div>
          <div className='col-xs-12 col-md-4'>
            3.) Use the downloaded CSV to import your guest list into your With
            Joy site.
          </div>
        </div>
      </div>
      <p>
        We do not store any of your guest information. All of the conversion
        happens in the browser!
      </p>
    </div>
  )
}

export default Postable
