import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Home from './pages/Home'
import Postable from './pages/Postable'
// import Login from './pages/Login'
// import Signup from './pages/Login/Signup'

import Footer from './components/Footer'
import Navbar from './components/Navbar'
// import AdSlot from './components/Ads/AdSlot'

import 'flexboxgrid/css/flexboxgrid.min.css'
import './App.css'

function App() {
  return (
    <div>
      <BrowserRouter>
        {/* <GlobalStyles /> */}
        <Navbar />
        <div className='App'>
          <Routes>
            {/* <Route path='login' element={<Login />} /> */}
            {/* <Route path='signup' element={<Signup />} /> */}
            <Route path='postable-to-withjoy' element={<Postable />} />
            <Route index element={<Home />} />
          </Routes>
        </div>
        <Footer />
        {/* <AdSlot name='anchor' /> */}
      </BrowserRouter>
    </div>
  )
}

export default App
