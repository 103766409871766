import postable from '../logos/postable.png'
import withjoy from '../logos/withjoy.png'

const FromTo = () => {
  return (
    <span className='fromto'>
      <img src={postable} /> to <img src={withjoy} />
    </span>
  )
}

export default FromTo
