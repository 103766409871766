import { Link } from 'react-router-dom'

import FromTo from '../../components/FromTo'

import './style.css'

const Home = () => {
  return (
    <div>
      <div className='banner'>
        <h1>Tools to Make Wedding Websites Easier</h1>
      </div>
      <div className='container-fluid home-section'>
        <h2>Guest List Converters</h2>
        <Link to='/postable-to-withjoy' className='tool'>
          <FromTo />
          <span>Postable to WithJoy</span>
        </Link>
      </div>
      <div className='container-fluid home-section'>
        <h3>Why I made this site</h3>
        <p>
          I was frustrated that each wedding website had bits and pieces of what
          I needed when it came to planning. Being a programmer, I found myself
          writing code to help with all the pain of manual tasks like moving
          guest list from one service to another. I realized that other people
          could probably use the help and decided to publish these tools online
          for free!
        </p>
      </div>
    </div>
  )
}

export default Home
